@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Lexend', sans-serif;
}
* ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
* ::-webkit-scrollbar-thumb {
  background: #344e59;
  border-radius: 10px;
  cursor: hand;
}
* ::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #bbb;
}

.wrapper{
  max-width: 90%;
  margin-right: 5%; /* 1 */
  margin-left:  5%; /* 1 */
  padding-right: 10px; /* 3 */
  padding-left:  10px; /* 3 */
}

.banner-content {
  width: 100%;
  height: 100vh;
}
hr {
  height: 0.5px;
  width: 120px;
  margin:  auto;
}
.home-image{
  height : 80vh;
  width  : 100%;
  background-image : url('./Components/Images/Home/bg-image/IMG_20190119_124141.jpg');
  background-size: cover;
  background-position: center;
}
/*# sourceMappingURL=App.css.map */

@media (max-width:576px){
  .home-image{
    height: 35vh;
  }
}


.date-field::placeholder {
  color: transparent;
}
.TourPage-banner{
  height: 75vh;
  width:100%;
  background-color:#344e59;
  
} 
.galleryContainer{
  width:100%;
  padding:5rem 0;
}
.gallery_box{
  width:90%;
  display: flex;
  justify-content: center;
  margin:auto;
}
.gallery{
  margin:auto;
  display: flex;
  justify-content:center ;
  flex-wrap: wrap;
}


